import Layout from "../../layouts"
import React, {useState, useContext, useEffect} from "react"
import {GlobalStateContext} from "../../context/GlobalContextProvider"
import {Box,  Card} from "@mui/material"

import Hider from "../../components/utils/Hider"
import CfpModify from "../../components/cfp/cfpmodify"
import {CfpDgBindMember} from "../../components/cfp/cfpdialogbindmember"
import {CfpBackTitle} from "../../components/cfp/cfpdetailstop"
import {CfpCmDetails} from "../../components/cfp/cfpdetails"
import {gUtils} from "../../components/utils/gutils";
import {navigate} from "gatsby";
import {gConfig} from "../../chatai/utils/config";
import CheckTable from "../../components/attendance/checkTable";
import {wordConfig} from "../../config/configFont";

export default function PasswordDetails({location = {}}) {
    const {state = {}} = location

    const {data} = state || {};
    const {
        gCfpfp, gCfp, ts, gIotCfp,

        setCustomModalOpen,


        setModalContent
    } = useContext(GlobalStateContext)
    const [edit, setEdit] = useState(false)
    const [isModifyName, setIsModifyName] = useState(true)
    const [mdetails, setMdetails] = useState({})
    const [isBindMember, setIsBindMember] = useState(false)
    const [mTag, setMTag] = useState('暗証番号名')
    const [modifyName, setModifyName] = useState('')

    const filterList = (list, passwordID) => {
        let uuids = []
        list.map(item => {
            if (item.passwordID === passwordID) {
                uuids.push(item.deviceID)
            }
        })


        return uuids;
    };
    useEffect(() => {
        console.log("数据进入", state)
        if (!data || Object.keys(data).length === 0) {
            navigate("/");
        } else {

            setMdetails({
                [wordConfig.touchIndex]: state.index,
                [wordConfig.touchPinNumber]: gUtils.binaryToDecimal(data.passwordID) ?? "-",
                [wordConfig.touchCertEquipment]: gUtils.uuidsToNames(filterList(gCfpfp.devicePasswords, data.passwordID), gCfp.cfpDevices),
            })
            setModifyName(data.name)
        }


    }, [state, data, navigate,gCfpfp.devicePasswords])


    useEffect(() => {
        gIotCfp.subIotDevices()
        return () => {
            gIotCfp.cancelSubscriptions();

        }
    }, []);
    const handleEditClick = () => {
        setShowEdit(true)
    }
    const setShowEdit = (isName) => {
        setIsModifyName(isName)
        if (isName) {
            setMTag('暗証番号名')
            setModifyName(data.name)
        } else {
            setMTag('暗証番号')
            setModifyName(gUtils.binaryToDecimal(data.id))
        }
        setEdit(true)


    }

    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleSureClick = async (v) => {
        setEdit(false)
        if (isModifyName) {
            data.name = v
            gCfpfp.updateFp("p", gCfp.touchId, data)
        } else {
            if (!gCfp.getWifiState(gCfp.touchId)) {
                ts({open: true, msg: "TouchPro Wi-Fiに接続してください"})
                return
            }

            await gIotCfp.sendCmd(data.deviceID, gConfig.cmdCode.passwordDelete, gUtils.toCardInfo(data.passwordID, 0, data.name))
            data.passwordID = formatNumber(v)
            setMdetails({
                [wordConfig.touchIndex]: state.index,
                [wordConfig.touchPinNumber]: gUtils.binaryToDecimal(data.passwordID) ?? "-",
                [wordConfig.touchCertEquipment]: gUtils.uuidsToNames(filterList(gCfpfp.devicePasswords, data.passwordID), gCfp.cfpDevices),

            })

            await delay(2000)
            await gIotCfp.sendCmd(data.deviceID, gConfig.cmdCode.passwordPut, gUtils.toCardInfo(formatNumber(v), 0, data.name))


        }

    }

    function formatNumber(inputNumber) {
        return Array.from(inputNumber.toString(), digit => digit.padStart(2, '0')).join('');
    }

    const handleClose = () => {
        setIsBindMember(false)
    }

    const handleSure = (mm) => {
        setIsBindMember(false)

        data.memberID = mm.subUUID
        gCfpfp.updateFp("p", gCfp.touchId, data)
    }

    const handleBindMmClick = () => {
        setIsBindMember(true)
    }
    const handleCheck = async (items) => {

        setCustomModalOpen(false)
        for (const item of items) {
            await gIotCfp.sendCmd(item.deviceUUID, gConfig.cmdCode.passwordPut, gUtils.toCardInfo(data.passwordID, 0, data.name))
            await new Promise(resolve => setTimeout(resolve, 2000))
        }


    }
    const handleOpenModal = () => {

        //  let  find=gCfpCards.allCards.find(item=>item.cardID===data.cardID)

   console.log("单大大撒大大",gCfp.cfpDevices)
        let value = gCfp.cfpDevices.filter(item => !filterList(gCfpfp.devicePasswords, data.passwordID).some(it => it === item.deviceUUID)).filter(item=>item.deviceModel
            !==gConfig.model.ssm_touch)
        setModalContent(
            <CheckTable
                title={"認証機器を選択"}
                setOpenModal={setCustomModalOpen}
                selectableRows={"multiple"}
                handleClose={() => {
                    setCustomModalOpen(false)
                }}
                data={value}
                handleCheck={handleCheck}
                location={location}
                isWifi={true}
            />
        )
        setCustomModalOpen(true)
    }
    return (
        <Layout location={location}>
            <Card>
                <Hider show={edit}>
                    <CfpModify
                        tag={mTag}
                        type="p"
                        name={modifyName}

                        leftClick={() => {

                            setEdit(false)
                        }}
                        sureClick={handleSureClick}
                    />
                </Hider>
                {!edit && (
                    <Box>
                        <CfpBackTitle title={data?.name || ''} clickEdit={handleEditClick}/>
                        <CfpCmDetails mdetails={mdetails} bindMm={handleBindMmClick} bindTouch={handleOpenModal}
                                      editClick={(v) => {
                                          setShowEdit(false)
                                      }}
                                      delBot={(i) => {
                                          let uuids = filterList(gCfpfp.devicePasswords, data.passwordID)
                                          if (gCfp.getWifiState(uuids[i])) {
                                              gIotCfp.sendCmd(uuids[i], gConfig.cmdCode.passwordDelete, gUtils.toCardInfo(data.passwordID, 0, data.name))

                                          }

                                      }}

                        />
                    </Box>
                )}
            </Card>
            <CfpDgBindMember
                mOpen={isBindMember}
                handleClose={handleClose}
                handleSure={handleSure}
            />
        </Layout>
    )
}
